<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="event_live"
                                    label="Event Live"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-autocomplete
                                    v-model="item_ghim"
                                    :items="list_products"
                                    :search-input.sync="search_product_ghim"
                                    label="Sản phẩm ghim"
                                    item-text="Name"
                                    item-value="Id"
                                ></v-autocomplete>
                            </div>

                            <div class="col-md-6">
                                <v-autocomplete
                                    v-model="list_item"
                                    :items="list_products"
                                    :search-input.sync="search_product"
                                    label="Danh sách sản phẩm"
                                    item-text="Name"
                                    item-value="Id"
                                    :multiple="true"
                                    chips
                                    deletable-chips
                                ></v-autocomplete>
                            </div>


                            <div class="col-md-6">
                                <v-text-field
                                    v-model="description"
                                    label="Mô tả"
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <span>Trạng thái</span>
                                <v-switch v-model="is_active"></v-switch>
                            </div>

                            <div class="col-md-6">
                                <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="create_event">Thêm</v-btn>
                                <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Live Events'
    },
    data() {
        return {
            valid: true,
            event_live: "",
            item_ghim: null,
            list_item: [],
            description: "",
            is_active: true,
            loading: false,
            name_rules: [],
            queryProduct: '',
            queryProductGhim: '',
            list_products: [],
            loading_search_product: false,
        };
    },
    watch: {
        
    },
    computed: {
        search_product: {
            get () {
                return this.queryProduct
            },
            
            set (searchInput) {
                if (this.queryProduct !== searchInput) {
                    this.queryProduct = searchInput
                    this.search_products(this.queryProduct);
                }
            }
        },
        search_product_ghim: {
            get () {
                return this.queryProductGhim
            },
            
            set (searchInput) {
                if (this.queryProductGhim !== searchInput) {
                    this.queryProductGhim = searchInput
                    this.search_products(this.queryProductGhim);
                }
            }
        },
        products() {
            return this.$store.getters['products/get_products'].length ? this.$store.getters['products/get_products'] : ['Loading...'];
        },
    },
    components: {

    },
    methods: {
        create_event() {

            this.loading = true;
            var payload = {
                Event_live: this.event_live,
                Id_item_gim: this.item_ghim,
                List_id_item_live: this.list_item.join(","),
                Description_event: this.description,
                Is_active: this.is_active == true ? 1 : 0,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('events/create_event', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'events-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'events-index' });
        },
        
        search_products(val) {
            this.$store.dispatch('products/get_all', {pageSize: 10, pageIndex: 1, categoryId: -1, nameQuery: val})
            .then(res => {
                if(res.status == 200 && res.data) {
                    if(res.data.Message == "Success") {
                        if(res.data.Data) {
                            if(res.data.Data.Items) {
                                this.append_list_products(res.data.Data.Items);
                            }
                        }
                        
                    }
                }
            }).catch(e => {
                console.log(e);
            });
        },

        append_list_products(list) {
            for(let i = 0; i < list.length; i++) {
                let is_exists = false;
                for(let j = 0; j < this.list_products.length; j++) {
                    if(list[i].Id == this.list_products[j].Id) {
                        is_exists = true;
                        break;
                    }
                }
                if(!is_exists) {
                    this.list_products.push(list[i]);
                }
            }
        }

    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Live Events", route: "index" }, { title: "Thêm Live Events" } ]);
        // this.$store.dispatch('products/get_all', {pageSize: 50, pageIndex: 1, categoryId: -1});
    }
};
</script>



